<script setup>
import NavLink from './NavLink.vue'
import RelayRouterButton from './RelayRouterButton.vue'
import RelayButton from './RelayButton.vue'
import axios from 'axios'
import { userStore } from '@/scripts/store'

const store = userStore();

//https://devblogs.microsoft.com/dotnet/whats-new-with-identity-in-dotnet-8/
// external auth for when we get there again

//also bc it took forever to learn
//https://andrewlock.net/should-you-use-the-dotnet-8-identity-api-endpoints/
</script>

<template>
  <div v-if="!store.isAuthenticated">
    <RelayRouterButton class="button" to="/join" msg="Get Started Free" />
    <NavLink to="/login" msg="Login" />
  </div>
  <div v-else>
    <NavLink to="/dashboard" :msg="'Dashboard'" />
    <RelayRouterButton to="/" @click="store.logout()" msg="Logout" class="button logout"/>
  </div>
</template>

<style scoped>
div {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  div {
    flex-direction: column;
    align-items: flex-start;
  }

  .button {
    margin-left: 1rem;
  }

  .logout {
    margin-bottom: 1rem;
  }
}
</style>